import classNames from 'classnames';
import SEO from 'components/seo/seo';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import parse from 'html-react-parser';
import './glossary.template.scss';
import { alphabet } from './glossary.config';
import gsap from 'gsap';
import { handlePageLoaded } from 'components/trans/transLink';

interface Props {
  pageContext: pageContextProps;
  // data: {
  //   glossary: {
  //     edges: {
  //       node: {
  //         title: string;
  //         content: string;
  //       };
  //     }[];
  //   };
  // };
}

interface GlossaryData {
  glossary: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
}

const GlossaryTemplate: React.FC<Props> = (props) => {
  const itemArRef = useRef<(HTMLDivElement | null)[]>([]);
  const alphabetArRef = useRef<(HTMLDivElement | null)[]>([]);
  const sectionRef = useRef<HTMLDivElement>(null);
  const spacerRef = useRef<HTMLDivElement>(null);

  const glossaryData: GlossaryData = useStaticQuery(
    graphql`
      query GlossaryQuery {
        glossary: allWpGlossary(sort: { fields: title, order: ASC }) {
          edges {
            node {
              title
              content
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    handlePageLoaded();
  }, []);

  // set active on scroll
  useEffect(() => {
    const handleScroll = () => {
      const topOffset = sectionRef.current?.offsetTop || 0;
      let title: string | null = null;
      itemArRef.current.forEach((item) => {
        if (!!item) {
          const spacerHeight = spacerRef.current?.clientHeight || 0;
          const top = item.offsetTop - topOffset - window.scrollY + spacerHeight;
          if (top >= 0 && !title) {
            title = item.getAttribute('data-title');
          }
        }
      });

      alphabetArRef.current.forEach((item) => {
        if (!!item) {
          const letter = item.getAttribute('data-letter') || '';
          if (title?.startsWith(letter)) {
            item.classList.add('is-active');
          } else {
            item.classList.remove('is-active');
          }
        }
      });
    };
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  const handleAlphabetClick = (e: MouseEvent) => {
    const letter = e.currentTarget.getAttribute('data-letter')?.substring(0, 1);
    let scrollTo = -1000;
    itemArRef.current.forEach((item) => {
      if (!!item && !!letter && scrollTo < 0) {
        const spacerHeight = spacerRef.current?.clientHeight || 0;
        const title = item.getAttribute('data-title');
        if (title?.startsWith(letter)) {
          scrollTo = item.offsetTop - spacerHeight;
        }
      }
    });

    if (scrollTo >= 0) {
      const topOffset = sectionRef.current?.offsetTop || 0;
      gsap.to(window, {
        duration: 0.4,
        scrollTo: scrollTo - topOffset,
      });
    }
  };

  return (
    <div className={classNames('glossary-template', 'is-loading-complete')} id={props.pageContext.id}>
      <SEO {...props.pageContext.seo} />
      <section className="section" ref={sectionRef}>
        <div className="section-content">
          <div className="glossary-wrapper">
            <div className="glossary-sticky">
              <div className="section-header">
                <h5 className="section-header__title" data-title={props.pageContext.title.toLowerCase()}>
                  {props.pageContext.title}
                </h5>
              </div>
              <div className="glossary-alphabet">
                {alphabet.map((item, key) => {
                  const contains = glossaryData.glossary.edges.find((entry) => entry.node.title.toLowerCase().startsWith(item));
                  return (
                    <div
                      key={key}
                      className={classNames('glossary-alphabet__item', { 'is-disabled': !contains })}
                      data-letter={item}
                      onClick={handleAlphabetClick}
                      ref={(ref) => (alphabetArRef.current[key] = ref)}
                    >
                      <span>{item}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="glossary-content">
              <div className="glossary-content__spacer" ref={spacerRef} />
              {glossaryData.glossary.edges.map((g, key) => {
                return (
                  <div
                    className="glossary-content__item"
                    key={key}
                    ref={(ref) => (itemArRef.current[key] = ref)}
                    data-title={g.node.title.toLocaleLowerCase()}
                  >
                    <h5>{g.node.title}</h5>
                    {!!g.node.content && parse(g.node.content)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GlossaryTemplate;

// export const query = graphql`
//   query ($id: String!, $language: String!) {
//     page: wpPage(id: { eq: $id }) {
//       title
//     }
//     glossary: allWpGlossary(sort: { fields: title, order: ASC }) {
//       edges {
//         node {
//           title
//           content
//         }
//       }
//     }
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
